import { DIMENSION } from '../../store/DIMENSION'
import { handleDragEvent } from '../startListenersDimension/utils/handleDragEvent'

export const clearSources = () => {
  DIMENSION.canvasElement?.removeEventListener('mousedown', handleDragEvent)
  DIMENSION.canvasElement?.removeEventListener('mousemove', handleDragEvent)
  DIMENSION.canvasElement?.removeEventListener('mouseup', handleDragEvent)

  DIMENSION.canvasElement?.removeEventListener('touchstart', handleDragEvent)
  DIMENSION.canvasElement?.removeEventListener('touchmove', handleDragEvent)
  DIMENSION.canvasElement?.removeEventListener('touchend', handleDragEvent)

  setTimeout(() => clearInterval(DIMENSION.interval), 100)
}
