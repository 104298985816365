import {
  getCountRouters,
  getHotmapAverage,
  getHotmapImage,
  getHotmapStrong,
  getHotmapWeak,
} from 'entities/Dimension'
import React, { FC, useEffect } from 'react'
import { useSelector } from 'react-redux'
import {
  DynamicModuleLoader,
  ReducersList,
} from 'shared/lib/components/DynamicModuleLoader'

import { saveResultApi, useSaveMutation } from '../../model/services/save/save'

interface SaveButtonProps {
  id: string | null | undefined
}

const initialReducers: ReducersList = {
  [saveResultApi.reducerPath]: saveResultApi.reducer,
}

const SaveButton: FC<SaveButtonProps> = ({ id }) => {
  const strong = useSelector(getHotmapStrong)
  const average = useSelector(getHotmapAverage)
  const weak = useSelector(getHotmapWeak)
  const countRouters = useSelector(getCountRouters)

  const image = useSelector(getHotmapImage)

  const [save] = useSaveMutation()

  useEffect(() => {
    const payload = {
      image_base64: image || '',
      router_count: countRouters,
      best_score: strong,
      average_score: average,
      worst_score: weak,
      flat_id: Number(id),
    }
    save?.(payload)
  }, [average, countRouters, id, image, save, strong, weak])

  return (
    <DynamicModuleLoader reducers={initialReducers} />
  )
}

export default SaveButton
