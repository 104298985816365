import { enhancedStore } from 'app/providers/storeProvider'

import { equipmentActions } from '../../../slice/equipmentSlice'
import { DIMENSION } from '../../../store/DIMENSION'

export const addRouter = (clientX: number, clientY: number, isChangeNextRouter: boolean = true) => {
  // Получаем размеры и позицию элемента
  const rect = DIMENSION.canvasElement?.getBoundingClientRect()

  if (rect) {
    // Вычисляем координаты клика относительно элемента
    const localX = clientX - rect.left
    const localY = clientY - rect.top

    const state = enhancedStore.getState()
    const routers = state.equipment?.routers || []

    const rx = Math.floor((localX - DIMENSION.X0 + DIMENSION.takeover) / 2)
    const ry = Math.floor((localY - DIMENSION.Y0 + DIMENSION.takeover) / 2)

    for (let i = 0; i < routers.length; i++) {
      const currentRouter = routers[i]
      const nextRouter = routers[i + 1]
      if (currentRouter.state === 2) {
        DIMENSION.Rx[i] = rx
        DIMENSION.Ry[i] = ry
        DIMENSION.PCounter = 0
        enhancedStore.dispatch(equipmentActions.setActiveRouter({ id: currentRouter.id, state: 3, positions: [localX - 14, localY - 14] }))
        if (nextRouter && isChangeNextRouter) {
          enhancedStore.dispatch(equipmentActions.setActiveRouter({ ...nextRouter, state: 1 }))
        }
        enhancedStore.dispatch(equipmentActions.incrementCountRouters())
      }
    }
  }
}
