import { ICanvasStore } from '../types/Canvas'

const img_walls = new Image()
img_walls.crossOrigin = 'anonymous'
img_walls.src = ''

export const DIMENSION: ICanvasStore = {
  Xcanvas: 0,
  Ycanvas: 0,
  img: img_walls,
  MaxTail: 15000, // максимальное количество квадратных тайлов на холсте
  takeover: 0,
  TS: 2, // time scale
  NImgx: 0, // количество тайлов только в изображении по оси X
  NImgy: 0, // количество тайлов только в изображении по оси Y
  Nx: 0, // количество тайлов изображения + буфер (7%х2) по оси X
  Ny: 0, // количество тайлов изображения + буфер (7%х2) по оси Y
  X0: 0,
  Y0: 0, // верхний левый угол (для центрирования)
  Wd: 0,
  Hg: 0, // ширина/высота изображения
  TailSize: 2, // размер tail для отображения
  Rx: [0, 0, 0],
  Ry: [0, 0, 0], // массив коодинат pacemakers
  Counter: 0, // сквозной счетчик кадров
  // ======================= bundle Parallel Fabers =============================
  PFz: [],
  PFvz: [],
  PFaz: [], // z [i,j] tails - coordinates, speed, acceleration 2xDemension
  PFm: [],
  PFc: [],
  // http://www.andrewnoske.com/wiki/Code_-_heatmaps_and_color_gradients
  PFheatmap: [],
  PFm2: [],
  PFc2: [],
  end: false,
  PCounter: -1,
  displayWaves: false,
  lastUpdateProgress: Date.now(),
  stage: 'prepare',
  stop: false,
  idDragRouter: null,
  isDragging: false,
  pressStartTime: null,
}
