import { enhancedStore } from 'app/providers/storeProvider'

import { addRouter } from './addRouter'

import { equipmentActions } from '../../../slice/equipmentSlice'
import { DIMENSION } from '../../../store/DIMENSION'

const LONG_PRESS_THRESHOLD = 500
let saveX: number | null = null
let saveY: number | null = null

let isMobile = false
let saveClientX = 0
let saveClientY = 0

const moveCallback = (x: number, y: number) => {
  const ghostElement = document.getElementById('ghost-router')
  if (ghostElement) {
    ghostElement.style.left = `${x - 10}px`
    ghostElement.style.top = `${y - 50}px`
  }
  saveClientX = x
  saveClientY = y
}

const endCallback = () => {
  if (DIMENSION.pressStartTime) {
    const pressDuration = Date.now() - DIMENSION.pressStartTime
    if (pressDuration >= LONG_PRESS_THRESHOLD) {
      addRouter(saveClientX, saveClientY, false)
    }
    else {
      // Получаем текущий список роутеров
      const state = enhancedStore.getState()
      const routers = [...(state.equipment?.routers || [])]

      // Находим индекс роутера, который нужно удалить
      const clickedRouterIndex = routers.findIndex(router => router.id === DIMENSION.idDragRouter)

      if (clickedRouterIndex !== -1) {
        // Смещаем роутеры
        for (let i = clickedRouterIndex; i < routers.length - 1; i++) {
          routers[i] = { ...routers[i + 1], id: routers[i].id } // Переносим следующий роутер на место текущего
        }

        // Последний роутер
        const lastRouterIndex = routers.length - 1

        // Проверяем предыдущий роутер
        const previousRouterState = lastRouterIndex > 0 ? routers[lastRouterIndex - 1].state : 0
        const newStateForLastRouter = previousRouterState === 3 ? 1 : 0

        // Обновляем последний роутер
        routers[lastRouterIndex] = {
          ...routers[lastRouterIndex],
          state: lastRouterIndex === 0 ? 1 : newStateForLastRouter,
          positions: [0, 0],
        }

        // Обновляем состояние в сторе
        enhancedStore.dispatch(equipmentActions.setRouters(routers))
      }
      if (saveX && saveY) {
        DIMENSION.ctx_hotmap?.clearRect(saveX, saveY, 28, 28)
        DIMENSION.ctx?.clearRect(saveX, saveY, 28, 28)
      }
    }

    DIMENSION.pressStartTime = null // Сбрасываем время
  }
  else {
    addRouter(saveClientX, saveClientY)
  }
  // Скрываем ghost-элемент
  const ghostElement = document.getElementById('ghost-router')
  if (ghostElement) {
    ghostElement.style.display = 'none'
    if (DIMENSION.canvasElement) {
      DIMENSION.canvasElement.style.cursor = `default`
    }
  }
  saveClientX = 0
  saveClientY = 0
  DIMENSION.isDragging = false
}

export const handleDragEvent = (event: MouseEvent | TouchEvent) => {
  const eventType = event.type
  let clientX = 0
  let clientY = 0

  if (event instanceof MouseEvent) {
    clientX = event.clientX
    clientY = event.clientY
  }
  else if (event instanceof TouchEvent && event.touches.length > 0) {
    const touch = event.touches[0] // Берем первый палец
    isMobile = true
    clientX = touch.clientX
    clientY = touch.clientY
  }
  const rect = DIMENSION.canvasElement?.getBoundingClientRect()

  if (rect && clientX && clientY) {
    const localX = clientX - rect.left
    const localY = clientY - rect.top

    const state = enhancedStore.getState()
    const routers = state.equipment?.routers || []

    if (eventType === 'mousedown' && !isMobile) {
      if (!saveClientX && !saveClientY) {
        saveClientX = clientX
        saveClientY = clientY
      }
    }
    else if (eventType === 'touchstart') {
      if (!saveClientX && !saveClientY) {
        saveClientX = clientX
        saveClientY = clientY
      }
    }

    if (eventType === 'mousemove' && !isMobile) {
      event.preventDefault()
      if (DIMENSION.pressStartTime) {
        const pressDuration = Date.now() - DIMENSION.pressStartTime
        if (pressDuration >= LONG_PRESS_THRESHOLD) {
          if (DIMENSION.isDragging) {
            moveCallback(clientX, clientY)
          }
        }
      }
    }
    else if (eventType === 'touchmove') {
      event.preventDefault()
      if (DIMENSION.pressStartTime) {
        const pressDuration = Date.now() - DIMENSION.pressStartTime
        if (pressDuration >= LONG_PRESS_THRESHOLD) {
          if (DIMENSION.isDragging) {
            moveCallback(clientX, clientY)
          }
        }
      }
    }

    for (let i = 0; i < routers.length; i++) {
      const currentRouter = routers[i]
      if (
        currentRouter.state === 3
        && currentRouter.positions.length === 2
      ) {
        const [routerX, routerY] = currentRouter.positions

        if (
          localX >= routerX - 32
          && localX <= routerX + 32
          && localY >= routerY - 32
          && localY <= routerY + 32
        ) {
          if (eventType === 'mousedown' && !isMobile) {
            DIMENSION.pressStartTime = Date.now()
            DIMENSION.idDragRouter = currentRouter.id
            DIMENSION.isDragging = true
            enhancedStore.dispatch(equipmentActions.decrementCountRouters())
            saveX = routerX
            saveY = routerY
            setTimeout(() => {
              if (DIMENSION.canvasElement) {
                if (DIMENSION.isDragging) {
                  enhancedStore.dispatch(equipmentActions.setActiveRouter({ ...currentRouter, state: 2 }))
                  DIMENSION.ctx_hotmap?.clearRect(routerX, routerY, 28, 28)
                  DIMENSION.ctx?.clearRect(routerX, routerY, 28, 28)
                  // Отображение ghost-элемента для роутера
                  const ghostElement = document.getElementById('ghost-router')
                  if (ghostElement) {
                    ghostElement.style.display = 'block'
                    ghostElement.style.left = `${clientX - 10}px`
                    ghostElement.style.top = `${clientY - 50}px`
                    DIMENSION.canvasElement.style.cursor = `grab`
                  }
                }
              }
            }, LONG_PRESS_THRESHOLD)
          }
          else if (eventType === 'touchstart') {
            DIMENSION.pressStartTime = Date.now()
            DIMENSION.idDragRouter = currentRouter.id
            DIMENSION.isDragging = true
            enhancedStore.dispatch(equipmentActions.decrementCountRouters())
            saveX = routerX
            saveY = routerY
            setTimeout(() => {
              if (DIMENSION.canvasElement) {
                if (DIMENSION.isDragging) {
                  enhancedStore.dispatch(equipmentActions.setActiveRouter({ ...currentRouter, state: 2 }))
                  DIMENSION.ctx_hotmap?.clearRect(routerX, routerY, 28, 28)
                  DIMENSION.ctx?.clearRect(routerX, routerY, 28, 28)
                  // Отображение ghost-элемента для роутера
                  const ghostElement = document.getElementById('ghost-router')
                  if (ghostElement) {
                    ghostElement.style.display = 'block'
                    ghostElement.style.left = `${clientX - 10}px`
                    ghostElement.style.top = `${clientY - 50}px`
                    DIMENSION.canvasElement.style.cursor = `grab`
                  }
                }
              }
            }, LONG_PRESS_THRESHOLD)
          }
        }
      }
    }
  }
  if (eventType === 'mouseup' && !isMobile) {
    setTimeout(endCallback, 10)
  }
  else if (eventType === 'touchend') {
    setTimeout(endCallback, 10)
  }
}
