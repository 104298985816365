import { Image, Skeleton } from '@chakra-ui/react'
import { pageDescriptionActions } from 'features/pageDescription'
import React, {
  FC,
  memo,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react'
import {
  DynamicModuleLoader,
  ReducersList,
} from 'shared/lib/components/DynamicModuleLoader'
import { getAbbreviatedText } from 'shared/lib/getAbbreviatedText/getAbbreviatedText'
import { useAppDispatch } from 'shared/lib/hooks/useAppDispatch'
import { resizeImage } from 'shared/lib/resizeImage'
import { Flex } from 'shared/ui/Flex'

import { clearSources } from '../../model/service/clearListeners/clearSources'
import {
  apartmentDimensionApi,
  useGetApartmentDimensionQuery,
} from '../../model/service/getApartmentDimension/getApartmentDimension'
import { startListenersDimension } from '../../model/service/startListenersDimension/startListenersDimension'
import { equipmentReducer } from '../../model/slice/equipmentSlice'
import { DIMENSION } from '../../model/store/DIMENSION'

interface DimensionCanvasProps {
  id: string | null | undefined
}

const initialReducers: ReducersList = {
  [apartmentDimensionApi.reducerPath]: apartmentDimensionApi.reducer,
  equipment: equipmentReducer,
}

const DimensionCanvas: FC<DimensionCanvasProps> = ({ id }) => {
  const canvasRef = useRef<HTMLCanvasElement>(null)
  const [canvasLoaded, setCanvasLoaded] = useState(false)
  const { data, isLoading } = useGetApartmentDimensionQuery(id)
  const dispatch = useAppDispatch()

  useEffect(() => {
    if (!isLoading) {
      if (data?.data) {
        const description = getAbbreviatedText(
          data?.data.item.numberRooms,
          data?.data.item.squareMeters,
        )
        dispatch(pageDescriptionActions.setDescription(description))
      }
      dispatch(pageDescriptionActions.setIsLoading(false))
    }
  }, [dispatch, data?.data, isLoading])

  useEffect(() => {
    return () => {
      clearSources()
    }
  }, [])

  const handleImageLoad = useCallback(
    (e: React.SyntheticEvent<HTMLImageElement, Event>) => {
      const Xcanvas = Math.round(e.currentTarget.width / 2) * 2
      const Ycanvas = e.currentTarget.height

      DIMENSION.Xcanvas = Xcanvas
      DIMENSION.Ycanvas = Ycanvas

      setCanvasLoaded(true)

      if (!canvasRef || !canvasRef.current) return
      DIMENSION.canvasElement = canvasRef.current

      const currCtx = canvasRef.current.getContext('2d', {
        willReadFrequently: true,
      })

      if (!currCtx) return
      currCtx.canvas.width = Xcanvas

      currCtx.canvas.height = Ycanvas
      currCtx.lineWidth = 1
      DIMENSION.ctx = currCtx
      if (e.currentTarget.src) {
        resizeImage(e.currentTarget.src, {
          width: Xcanvas,
          height: Ycanvas,
        }).then((res) => {
          DIMENSION.img = res as HTMLImageElement
          startListenersDimension()
        })
      }
    },
    [setCanvasLoaded, canvasRef],
  )

  return (
    <DynamicModuleLoader reducers={initialReducers}>
      <Flex
        w="100%"
        h="100%"
        p="24px 32px 24px 32px"
        flexDirection="column"
        justifyContent="center"
      >
        {isLoading
          ? (
              <Skeleton width="100%" height="100%" />
            )
          : (
              <Flex justifyContent="center">
                <canvas style={{ userSelect: 'none' }} ref={canvasRef} />
                {!canvasLoaded && (
                  <Image
                    src={data?.data.item.url}
                    onLoad={handleImageLoad}
                    maxH="60vh"
                    h="60vh"
                    minH="60vh"
                  />
                )}
              </Flex>
            )}
      </Flex>
    </DynamicModuleLoader>
  )
}

export default memo(DimensionCanvas)
