import { createSlice } from '@reduxjs/toolkit'

import { EquipmentSchema } from '../types/EquipmentSchema'

const initialState: EquipmentSchema = {
  routers: [
    {
      id: 1,
      state: 1,
      positions: [0, 0],
    },
    {
      id: 2,
      state: 0,
      positions: [0, 0],
    },
    {
      id: 3,
      state: 0,
      positions: [0, 0],
    },
  ],
  countRouters: 0,
}

export const equipmentSlice = createSlice({
  name: 'equipment',
  initialState,
  reducers: {
    setActiveRouter: (state, { payload }) => {
      state.routers = state.routers.map((router) => {
        if (router.id === payload.id) {
          return payload
        };
        return router
      })
    },
    setRouters: (state, { payload }) => {
      state.routers = payload
    },
    incrementCountRouters: (state) => {
      state.countRouters = state.countRouters + 1
    },
    decrementCountRouters: (state) => {
      if (state.countRouters) {
        state.countRouters = state.countRouters - 1
      }
    },
    resetRouters: (state) => {
      state.routers = [
        {
          id: 1,
          state: 1,
          positions: [],
        },
        {
          id: 2,
          state: 1,
          positions: [],
        },
        {
          id: 3,
          state: 1,
          positions: [],
        },
      ]
    },
    resetCountRouters: (state) => {
      state.countRouters = 0
    },
  },
})

// Action creators are generated for each case reducer function
export const { actions: equipmentActions } = equipmentSlice
export const { reducer: equipmentReducer } = equipmentSlice
